<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách người dùng hợp lệ</h4>
      </div>
      <div class="listwork__table__01">
        <!-- ===============================================bắt dầu bảng nhóm người dùng hợp lệ-------------------------------- -->
        <vue-good-table
          :select-options="{ enabled: true }"
          :columns="columns"
          :rows="dataInvalid || []"
          style-class="vgt-table"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'birthDate'">
              <span>{{ props.row.birthDate }}</span>
            </span>
          </template>
        </vue-good-table>
        <!-- ================================================kết thúc bảng nhóm người dùng hợp lệ-------------------------------- -->
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} người dùng</p>
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách người dùng không hợp lệ</h4>
        </div>
        <!-- ================================================nút chỉnh sửa  -------------------------------- -->
        <div class="listwork__head__02__right">
          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <!-- ================================================nút kiểm tra  -------------------------------- -->
          <div class="listwork__head__02__right__icon">
            <b-button
              v-b-tooltip.hover.top="'Kiểm tra'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>

          <div />
        </div>

      </div>
      <div class="listwork__table__02">
        <!-- ===============================================bắt dầu bảng nhóm người dùng không hợp lệ-------------------------------- -->
        <vue-good-table
          :columns="columns02"
          :rows="dataNoInvalid || []"
          :select-options="{ enabled: false , selectOnCheckboxOnly: true,}"
          style-class="vgt-table"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              :id="'tooltip' + props.row.originalIndex"
              v-b-tooltip.hover.right.html="props.row.messageError"
              variant="outline-danger"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->

            <!--======================= Mã người dùng lỗi =================================== -->
            <span v-if="props.column.field == 'code' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'Code'? 'text-danger' :'' "
              >{{ props.row.code }}</span>
            </span>

            <span v-if="props.column.field == 'code' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Code'"
                v-model="props.row.code"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.code, 'code', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Code'"
              >{{ props.row.code }}</span>
            </span>

            <!--======================= Tên người dùng lỗi =================================== -->
            <span v-if="props.column.field == 'name' && editvalueErr === false">
              <span
                :class="
                  props.row.messErr[0].location === 'Name'? 'text-danger' :'' "
              >{{ props.row.name }}</span>
            </span>

            <span v-if="props.column.field == 'name' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Name'"
                v-model="props.row.name"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.name, 'name', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Name'"
              >{{ props.row.name }}</span>
            </span>

            <!--======================= Email người dùng lỗi =================================== -->
            <span v-if="props.column.field == 'email' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'Email'? 'text-danger' :'' "
              >{{ props.row.email }}</span>
            </span>

            <span v-if="props.column.field == 'email' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Email'"
                v-model="props.row.email"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.email, 'email', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Email'"
              >{{ props.row.email }}</span>
            </span>

            <!--=======================Tên tài khoản người dùng lỗi =================================== -->
            <span v-if="props.column.field == 'userName' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'UserName'? 'text-danger' :'' "
              >{{ props.row.userName }}</span>
            </span>

            <span v-if="props.column.field == 'userName' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'UserName'"
                v-model="props.row.userName"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.userName, 'userName', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'UserName'"
              >{{ props.row.userName }}</span>
            </span>

            <!--=======================Mật khẩu người dùng lỗi =================================== -->
            <span v-if="props.column.field == 'password' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'Password'? 'text-danger' :'' "
              >{{ props.row.password }}</span>
            </span>

            <span v-if="props.column.field == 'password' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Password'"
                v-model="props.row.password"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.password, 'password', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Password'"
              >{{ props.row.password }}</span>
            </span>

            <span v-if="props.column.field == 'birthDate'">
              <span>{{ props.row.birthDate }}</span>
            </span>

            <!--=======================Giới tính người dùng lỗi =================================== -->
            <span v-if="props.column.field == 'gender' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'Gender'? 'text-danger' :'' "
              >{{ props.row.gender }}</span>
            </span>

            <span v-if="props.column.field == 'gender' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Gender'"
                v-model="props.row.gender"
                :reduce="value => value.name"
                :label="'name'"
                :placeholder="`Lựa chọn giới tính`"
                :options="genderData"
                @input="changeCellvalue(props.row.gender, 'gender', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Gender'"
              >{{ props.row.gender }}</span>
            </span>

            <span v-if="props.column.field == 'address'">
              <span>{{ props.row.address }}</span>
            </span>
            <span v-if="props.column.field == 'phoneNumber'">
              <span>{{ props.row.phoneNumber }}</span>
            </span>
            <!--=======================Kiểu người dùng lỗi =================================== -->
            <span v-if="props.column.field == 'userType' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'UserType'? 'text-danger' :'' "
              >{{ props.row.userType }}</span>
            </span>

            <span v-if="props.column.field == 'userType' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'UserType'"
                v-model="props.row.userType"
                :reduce="data => data.name"
                :label="'name'"
                :placeholder="`Lựa chọn loại doanh nghiệp `"
                :options="dataCombobox"
                @input="changeCellvalue(props.row.userType, 'userType', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'UserType'"
              >{{ props.row.userType }}</span>
            </span>

            <!--=======================Nhóm người dùng lỗi =================================== -->
            <span v-if="props.column.field == 'groupUser' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'GroupUser'? 'text-danger' :'' "
              >{{ props.row.groupUser }}</span>
            </span>

            <span v-if="props.column.field == 'groupUser' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'GroupUser'"
                v-model="props.row.groupUser"
                :reduce="data => data.name"
                :label="'name'"
                :placeholder="`Lựa chọn nhóm người dùng`"
                :options="dataGroupUsser"
                @input="changeCellvalue(props.row.groupUser, 'groupUser', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'GroupUser'"
              >{{ props.row.groupUser }}</span>
            </span>

            <!--=======================Cơ cấu tổ chức lỗi =================================== -->
            <span v-if="props.column.field == 'orStruc' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'OrStruc'? 'text-danger' :'' "
              >{{ props.row.orStruc }}</span>
            </span>

            <span v-if="props.column.field == 'orStruc' && editvalueErr === true ">
              <organization-select
                v-if="props.row.messErr[0].location === 'OrStruc'"
                v-model="props.row.parentIdName"
                class="cssInput"
                @input="(id) => {
                  return changeCellOrStruct(id, props.index, props.row.parentIdName, 'orStruc', props.row)
                }"
                @checkNameText="checkNameText"
              />
              <span
                v-if="props.row.messErr[0].location !== 'OrStruc'"
              >{{ props.row.orStruc }}</span>
            </span>

            <!--=======================Vị trí công việc lỗi =================================== -->
            <span v-if="props.column.field == 'position' && editvalueErr === false ">
              <span
                :class="
                  props.row.messErr[0].location === 'Position'? 'text-danger' :'' "
              >{{ props.row.position }}</span>
            </span>

            <span v-if="props.column.field == 'position' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Position'"
                v-model="props.row.position"
                :reduce="data => data.name"
                :label="'name'"
                :placeholder="`Lựa chọn vị trí công việc `"
                :options="props.row.positionOptions"
                @input="changeCellvalue(props.row.position, 'position', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Position'"
              >{{ props.row.position }}</span>
            </span>

          </template>
        </vue-good-table>
        <!-- ================================================kết thúc bảng nhóm người dùng không  hợp lệ-------------------------------- -->
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }} người dùng</p>
      </div>

      <div class="listwork__button">
        <!-- ================================================nút thêm nhóm người dùng-------------------------------- -->
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addUser"
          >
            Thêm người dùng
          </b-button>
        </div>
        <!-- ================================================nút hủy bỏ  -------------------------------- -->
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="closeExcel"
          >
            Hủy bỏ
          </b-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BButton, BBreadcrumb, BBreadcrumbItem, VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import OrganizationSelect from '@/views/management-user/users/pages/components/OrganizationSelect.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {
    VueGoodTable,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    VBTooltip,
    VSelect,
    OrganizationSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      formatFullName,
      dataInvalid: [],
      dataNoInvalid: [],
      genderData: [
        {
          name: 'Nam',
        },
        {
          name: 'Nữ',
        },
      ],
      editvalueErr: false, // bật tắt chỉnh sửa
      columns: [
        {
          label: 'Mã người dung',
          field: 'code',
          sortable: false,
        },
        {
          label: 'Tên người dùng',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Email',
          field: 'email',
          sortable: false,
        },
        {
          label: 'Tên tài khoản',
          field: 'userName',
          sortable: false,
        },
        {
          label: 'Mật khẩu',
          field: 'password',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDate',
          sortable: false,
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'Địa chỉ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'Số điện thoại',
          field: 'phoneNumber',
          sortable: false,
        },
        {
          label: 'Kiểu người dùng',
          field: 'userType',
          sortable: false,
        },
        {
          label: 'Nhóm người dùng',
          field: 'groupUser',
          sortable: false,
        },
        {
          label: 'Vị trí công việc',
          field: 'position',
          sortable: false,
        },
        {
          label: 'Cơ cấu tổ chức',
          field: 'orStruc',
          sortable: false,
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
          sortable: false,
        },
        {
          label: 'Mã người dung',
          field: 'code',
          sortable: false,
        },
        {
          label: 'Tên người dùng',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Email',
          field: 'email',
          sortable: false,
        },
        {
          label: 'Tên tài khoản',
          field: 'userName',
          sortable: false,
        },
        {
          label: 'Mật khẩu',
          field: 'password',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDate',
          sortable: false,
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'Địa chỉ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'Số điện thoại',
          field: 'phoneNumber',
          sortable: false,
        },
        {
          label: 'Kiểu người dùng',
          field: 'userType',
          sortable: false,
        },
        {
          label: 'Nhóm người dùng',
          field: 'groupUser',
          sortable: false,
        },
        {
          label: 'Cơ cấu tổ chức',
          field: 'orStruc',
          sortable: false,
        },
        {
          label: 'Vị trí công việc',
          field: 'position',
          sortable: false,
        },
      ],
      dataGroupUsser: [],
      dataPosition: [],
      textNameParent: [],
      orStrucName: null,
      tempData: [],
    }
  },
  computed: {
    ...mapGetters('users', ['dataValidUser', 'dataNoValidUser', 'dataTooltip']),
    ...mapGetters('userType', ['dataCombobox']),
  },
  mounted() {
    this.doFetchUserTypeCombobox()
  },
  created() {
    this.dataInvalid = this.dataValidUser
    this.dataNoInvalid = this.dataNoValidUser
    this.checkErrorUser(this.dataTooltip)
    this.fetchDataGroupUser()
  },
  methods: {
    ...mapActions('users', ['getApiExcelUser']),
    ...mapActions('userType', [
      'doFetchUserTypeCombobox',
    ]),

    checkNameText(value) {
      this.orStrucName = value.name
    },

    async fetchDataGroupUser() {
      const { data } = await axiosApiInstance.get('/GroupUser/get-combobox-group-user')
      this.dataGroupUsser = data?.data
    },

    async fetchDataPositionUser(id) {
      const { data } = await axiosApiInstance.get('/Position/get-combobox-position', {
        params: { organizationStructureId: id },
      })
      this.dataPosition = data?.data
      return data?.data
    },

    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },
    async changeCellOrStruct(id, index, event, field, rowData) {
      const positionOptions = await this.fetchDataPositionUser(id)
      this.dataNoInvalid[index].positionOptions = positionOptions
      this.dataNoInvalid[rowData.originalIndex][field] = this.orStrucName
      this.dataNoInvalid.forEach((x, ind) => {
        if (ind === index) x.parentIdName = id
      })
    },
    async CheckdataNoInvalid() {
      this.tempData = this.dataNoInvalid
      const model = {
        listExcel: this.dataNoInvalid,
        isValidate: true,
      }
      const valuedata = await this.getApiExcelUser(model)
      const dataInvalid01 = valuedata.data.data.filter(x => x.isSuccess === true)
      this.dataInvalid = this.dataInvalid.concat(dataInvalid01)
      const dataNoInvalid01 = valuedata.data.data.filter(x => x.isSuccess === false)
      if (dataNoInvalid01.length > 0) {
        dataNoInvalid01.forEach(item => {
          const namexxx = this.tempData.find(element => element.orStruc === item.orStruc)
          if (namexxx) {
            item.positionOptions = namexxx.positionOptions
          }
        })
        this.dataNoInvalid = dataNoInvalid01
        this.checkErrorUser(this.dataNoInvalid)
      } else {
        this.dataNoInvalid = []
      }
    },
    addUser() {
      const model = {
        listExcel: this.dataInvalid,
        isValidate: false,
      }
      this.getApiExcelUser(model).then(res => {
        if (res.status === 200 && this.dataInvalid.length > 0) {
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.dataInvalid = []
          if (this.dataNoInvalid.length === 0) {
            this.$router.push({ name: 'list-user' })
          }
        } else {
          this.$root.$bvToast.toast('Thêm không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },
    closeExcel() {
      this.dataInvalid = []
      this.dataNoInvalid = []
      this.$router.push({ name: 'list-user' })
    },

    checkErrorUser(data) {
      // kiểm tra lỗi
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i]
          if (element.isSuccess === false) {
            /* eslint no-loop-func: "error" */
            let messageError = ''
            if (element.messErr) {
              element.messErr.forEach(err => {
                messageError += `${this.$t(`${err.message}`)} <br> `
              })
            }
            data[i].messageError = messageError
          } else { element.isSelected = true }
        }
      }
    },
  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}

/* Breadcrumb  */
.header{
display:flex;
margin-bottom: 32px;

}
.header-title{
height:26px;
font-weight: 500;
font-size: 21px;
line-height:25,6px;
padding-right: 20px;
position: relative;
color:#2E3A4A;
}
.header-title::after{
  content:"";
  display: block;
  position: absolute;

    right: 0;
    top: 0;

    width:1px;
    height:26px;
    background-color: #CBCDD2;
}
.header-breadcrumb{
font-size:14px;
height:21px;
line-height:21px;
display: flex;
align-content: center;
padding: 0;
}
a {
    color: #227FF4;
    text-decoration: none;
}
.breadcrumb-item.active {
    color: #2E3A4A;
}
[dir] .breadcrumb{
padding-left: 20px;
}
/* end breadcrumb */

.text-danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}

</style>
